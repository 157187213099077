<template>
  <MemProfCreationWrapper current-step="Krok 1 z 3: Widoczność profilu">
    <template #step-indicators>
      <span class="step-indicator active"></span>
      <span class="step-indicator"></span>
      <span class="step-indicator"></span>
    </template>

    <div class="step-content">
      <h3 class="step-title">Widoczność profilu</h3>

      <v-divider class="step-header-separator"></v-divider>

      <p class="step-description">Zdecyduj na jakich zasadach ma się wyświetlać tworzony przez Ciebie profil</p>

      <v-radio-group v-model="localSelectedVisibility" class="radio-group" row>
        <v-row>
          <v-col>
            <v-radio value="public">
              <template #label>
                <p class="radio-label">Profil publiczny</p>
              </template>
            </v-radio>

            <div class="description">
              Widoczny publicznie, każdy posiadający link lub skanujący QR Code może obejrzeć historię życia
              upamiętnianej osoby.
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-radio value="private">
              <template #label>
                <p class="radio-label">Profil prywatny</p>
              </template>
            </v-radio>

            <div class="description">
              Zabezpieczony kodem dostępu. Sam zdecydujesz komu go udostępnisz.
            </div>
          </v-col>
        </v-row>
      </v-radio-group>

      <v-row v-if="localSelectedVisibility === 'private'">
        <v-col class="private-access-code">
          <p class="small-text access-code-message">
            Ustaw kod dostępu (PIN) do profilu:
          </p>

          <v-otp-input
            ref="otpInputRef"
            v-model="localAccessCode"
            variant="solo-filled"
            length="5"
            :rules="[v => !!v || 'Access code is required', v => /^\d{5}$/.test(v) || 'Kod musi składać się z 5 cyfr']"
          />
        </v-col>
      </v-row>
    </div>
  </MemProfCreationWrapper>
</template>

<script>
import MemProfCreationWrapper from "@/components/MemorialProfileCreationForm/MemProfCreationWrapper.vue";
import { useMemorialProfileStore } from '@/stores/memorial-profile';
import { computed, ref, watch, toRefs, watchEffect, nextTick } from 'vue';

export default {
  components: { MemProfCreationWrapper },
  emits: ['update:isFormValid'],
  setup(_, { emit }) {
    const store = useMemorialProfileStore();
    const { _memorialProfile } = toRefs(store);

    const localSelectedVisibility = ref(_memorialProfile.isPrivate ? 'private' : 'public');
    const localAccessCode = ref(_memorialProfile.accessCode);
    const otpInputRef = ref(null);

    // Watch for changes in the memorialProfile state and update local refs
    watchEffect(() => {
      const profile = store.memorialProfile;
      localSelectedVisibility.value = profile.isPrivate ? 'private' : 'public';
      localAccessCode.value = profile.accessCode;
    });
    watch([localSelectedVisibility, localAccessCode], ([newVisibility, newAccessCode]) => {
      const isFormValid = newVisibility === 'public' || (!!newAccessCode && newAccessCode.length === 5);

      emit('update:isFormValid', isFormValid);

      store.updateTempProfileState({
        isPrivate: newVisibility === 'private',
        accessCode: newAccessCode,
      });

      if (newVisibility === 'private') {
        nextTick(() => {
          document.querySelector('.v-otp-input__field')?.scrollIntoView({ top: -100, behavior: 'smooth' });
          otpInputRef.value?.focus();
        });
      }
    });

    return {
      localSelectedVisibility,
      localAccessCode,
      otpInputRef,
      memorialProfile: computed(() => store.memorialProfile),
    };
  }
}
</script>

<style lang="scss" scoped>
.description {
  font-size: 0.875rem;
  color: #666;
  padding-left: 40px;
  padding-bottom: 16px;
  margin-top: -10px;

  &:last-child {
    padding-bottom: 0;
  }
}

.v-col {
  padding: 0;
}

.access-code-message {
  margin-bottom: 0;
  border-color: #A28546;
}

.private-access-code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
}
</style>
